<template>
  <div class="patentAssess">
    <div>
      <nf-breadcrumb :breadList="[{ name: '知识产权' }, { name: '专利评估' }]"></nf-breadcrumb>
      <div class="searchWrap">
        <div class="title">专利价值评估</div>
        <el-input v-model="publicationNum" placeholder="请输入专利公开号">
          <el-button slot="append" @click="handleSearch">评估</el-button>
        </el-input>
        <div class="explain">
          说明                                 
          <br />专利市场价值评估-提供专利市场价值总评估，并且从市场吸引力、商场覆盖、技术质量、申请人得分和法律得分五个方面对专利进行分值评定。
          <br />专利智能评估-提供专利在技术、权利和市场等各方面的评价信息，主要根据专利的类型、发明难度、复杂程度、法律状态、专利申请人和权利人的数量与地位等诸多因素进行评价。
        </div>
        <div class="warning">
          注：本页面提供的专利价值评估信息仅供参考，若您需要明确了解一件专利的重要性、地位和价值。应注意参考多方面特别是业内专家的意见。
        </div>
      </div>
    </div>
    <div v-if="showDetail && !loading" class="detailWrap" v-loading="loading">
      <div class="valueWrap" v-if="!showEcharts && !isShow">
        <div class="value_one">专利市场价值评估</div>
        <div class="value_two" v-if="legalStatus == '公开'">该专利法律状态为“公开”，暂时不评估</div>
        <div class="value_two" v-else-if="legalStatus == '失效'">该专利已经失效，不进行价值评估</div>
        <div class="value_two" v-else-if="legalStatus == '授权'">专利价值暂时无法做出准确评估</div>
        <div class="value_two" v-else>该专利法律状态为“{{legalStatus}}”，暂时无法做出准确评估</div>
        <!-- <div class="value_two">暂无估值</div> -->
        <div class="value_three">提示：该专利尚未进行价值评估</div>
      </div>
      <div class="valueWrap" v-if="showEcharts && isShow">
        <div class="value_one">专利市场价值评估</div>
        <div class="value_two">￥{{showEcharts.patentValue}}</div>
        <div class="value_three">提示：该专利价值评估结果</div>
      </div>
      <div class="patentDetail">
        <div class="title">专利智能评估</div>
        <div class="patentName">
          {{ patentDetail_one.title.original || patentDetail_one.title['zh-cn'] || patentDetail_one.title.en || '暂无标题' }}
          <span> {{ patentDetail_one.countries[0] }} | {{ patentDetail_one.type == 1 ? '发明' : '实用' }}专利 </span>
        </div>
        <div
          class="proposer"
          v-if="
            patentDetail_one.applicants &&
              patentDetail_one.applicants.length > 0 &&
              patentDetail_one.applicants[0].name &&
              patentDetail_one.applicants[0].name.original
          "
        >
          申请人：{{ patentDetail_one.applicants[0].name.original }}
        </div>
        <div class="numAndTime">
          <span class="num">申请号：{{ patentDetail_one.application_number }}</span>
          <span class="time">
            申请日：{{
              patentDetail_one.application_date
                ? JSON.stringify(patentDetail_one.application_date).replace(/^(\d{4})(\d{2})(\d{2})$/, '$1年$2月$3日')
                : '-'
            }}
          </span>
        </div>
        <div class="inventor">发明人：{{ patentDetail_one.inventors[0].name.original }}</div>
      </div>
      <div class="evaluate">
        <div class="synthesize">
          <div class="title">
            综合评价
            <el-rate v-model="value" disabled></el-rate>
          </div>
          <div class="content">
            本指标综合反映了专利在技术、权利和市场等各方面的评价结果。 同时，若专利中缺少必要的技术信息，也会影响其本指标的评价结果。
            <br />若您希望在一组专利中快速找出值得首先关注、了解的少数专利，那么推荐您首先关注此指标。默认情况下，我们也会将这些专利排在前面来方便您查看。
          </div>
        </div>
        <div class="target">
          <div class="title">
            技术指标
            <el-rate v-model="value" disabled></el-rate>
          </div>
          <div class="content">
            本指标主要反映专利技术本身的地位和价值。专利的类型、发明难度和复杂程度、与其他技术的关联和技术的应用情况等，均会影响此指标的评价结果。若您希望在一组专利中快速找到一些值得参考、借鉴的技术，那么推荐您首先关注此指标。您可以在专利列表中选择按此指标来排列所有专利。
          </div>
        </div>
        <div class="portion">
          <div class="title">部分参考指标包括</div>
          <el-row>
            <el-col :span="4">专利类型</el-col>
            <el-col :span="8">实用新型</el-col>
            <el-col :span="4">被引专利数量</el-col>
            <el-col :span="8">{{ patentDetail_one.cited_patents.length }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="4">权利要求数量</el-col>
            <el-col :span="8">{{ patentClaim.clm_count || 0 }}</el-col>
            <el-col :span="4">独立权利要求数量</el-col>
            <el-col :span="8">{{ patentClaim.iclm_count || 0 }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="4">专利权质押次数</el-col>
            <el-col :span="8">{{ patentDetail_two.patent_legal_status.pledge.length }}</el-col>
            <el-col :span="4">专利权转移次数</el-col>
            <el-col :span="8">{{ patentDetail_two.patent_legal_status.change_assignees.length }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="4">发明人数量</el-col>
            <el-col :span="8">{{ patentDetail_one.inventors.length }}</el-col>
            <el-col :span="4">IPC分类号数量</el-col>
            <el-col :span="8">{{ patentDetail_one.ipcs.length }}</el-col>
          </el-row>
        </div>
        <div class="btnWrap">
          <div class="btn" @click="$router.push('/download')">下载APP查看更多</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';

export default {
  name: 'patentAssess',
  data() {
    return {
      publicationNum: '', // 专利公开号
      showDetail: false, // 是否展示详情
      patentDetail_one: null, // 专利详情一
      patentDetail_two: null, // 专利详情二
      patentClaim: null, // 专利权益
      loading: true, // 加载
      value: 1, // 评分
      timeOut: new Date(),
      legalStatus: '', // 
      showEcharts: '', // 价值
      isShow: true // 是否有价值
    };
  },
  methods: {
    // 点击评估
    async handleSearch() {
      console.log(new Date() - this.timeOut);
      if (new Date() - this.timeOut < 1500) {
        return true;
      }
      this.timeOut = new Date();
      Message.closeAll();
      if (!this.publicationNum) {
        Message.error({
          message: '请输入专利公开号',
          duration: 1500
        });
        return false;
      }
      const api = '/api/patent/open/get/id/by/publicationNumber?numPublication=' + this.publicationNum;
      const data = await this.$fetchData.fetchGet({}, api);
      console.log(data)
      this.getPatentClaim(data.result.rsData[0]);
      this.getDetail(data.result.rsData[0]);
    },
    // 获取专利详情
    async getDetail(id) {
      // this.loading = true;
      const api = this.$fetchApi.patentDetail.api + id;
      const data = await this.$fetchData.fetchGet({}, api, 'json');
      // this.loading = false;
      if (data.code === '200' && data.result) {
        if (data.result.rsData[0].patent) {
          // 专利详情赋值
          this.patentDetail_one = data.result.rsData[0].patent;
          this.patentDetail_two = data.result.rsData[0].legal;
          console.log(this.patentDetail_two.patent_legal_status.cls)
          this.legalStatus = this.patentDetail_two.patent_legal_status.cls;
          this.showDetail = true;
          this.patentPrice()
        } else {
          this.showDetail = false;
          Message.warning({
            message: '未查到相关数据',
            duration: 1500
          });
        }
      } else {
        this.showDetail = false;
        Message.error(data.Message);
      }
    },
    //  获取专利权益结果
    async getPatentClaim(id) {
      // this.loading = true;
      const api = this.$fetchApi.patentClaim.api + id;
      const data = await this.$fetchData.fetchGet({}, api);
      // this.loading = false;
      if (data.code === '200' && data.result) {
        this.patentClaim = data.result.rsData[0];
      }
    },
    // 专利价值
    async patentPrice() {
      this.loading = true;
      const api = this.$fetchApi.patentValue.api;
      const data = await this.$fetchData.fetchGet({}, api + '?patentNumber=' + this.patentDetail_one.publication_number);
      if (data.result) {
        this.showEcharts = data.result;
        if (this.showEcharts) {
          this.isShow = true
        } else {
          this.isShow = false
        }
      } else {
        this.isShow = false
      }
      this.loading = false;
    }
  },
  components: {
    nfBreadcrumb
  },
  created() {
    const publicationNum = this.$route.query.id;
    if (publicationNum) {
      this.publicationNum = publicationNum;
      this.handleSearch();
    }
  }
};
</script>

<style lang="less" scoped>
.patentAssess /deep/ .el-input__inner {
  height: 60px;
  font-size: 18px;
}
.patentAssess /deep/ .el-input-group__append {
  background: #409eff;
  width: 80px;
  text-align: center;
  font-size: 20px;
  color: #fff;
}
.patentAssess {
  min-height: 600px;
}
.searchWrap {
  .title {
    text-align: center;
    padding: 50px 0 50px 0;
    font-size: 35px;
    font-weight: 900;
  }
  .explain {
    font-size: 15px;
    padding-top: 20px;
    color: #666;
  }
  .warning {
    font-size: 15px;
    color: #cc0000;
    padding-top: 60px;
  }
  width: 1200px;
  margin: 0 auto;
}

.detailWrap {
  width: 1200px;
  margin: 50px auto;
  position: relative;
  .valueWrap {
    height: 200px;
    background: #fff;
    box-shadow: 0px 0px 10px #ddd;
    text-align: center;
    .value_one {
      font-size: 25px;
      color: #666;
      padding-top: 30px;
    }
    .value_two {
      color: #fd7d05;
      font-size: 32px;
      font-weight: 900;
      padding: 15px 0 20px 0;
    }
    .value_three {
      color: #f1a735;
      font-size: 20px;
    }
  }
  .patentDetail {
    height: 280px;
    background: #eee;
    box-shadow: 0px 0px 10px #ddd;
    margin-top: 20px;
    background-image: linear-gradient(rgba(255, 255, 255, 0.3) 2px, transparent 0),
      linear-gradient(90deg, rgba(255, 255, 255, 0.3) 2px, transparent 0);
    background-size: 5px 5px, 5px 5px;
    padding: 40px;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 25px;
      color: #666;
      padding-bottom: 20px;
    }
    .patentName {
      font-size: 28px;
      span {
        vertical-align: middle;
        margin-left: 30px;
        padding: 5px 10px;
        background: #fe7a07;
        font-size: 14px;
        color: #fff;
      }
    }
    .proposer {
      font-size: 18px;
      padding: 10px 0 25px;
    }
    .numAndTime {
      padding-bottom: 10px;
      span {
        margin-right: 80px;
      }
    }
    .numAndTime,
    .inventor {
      color: #999;
    }
  }
  .evaluate {
    padding: 40px;
    background: #fff;
    box-shadow: 0px 10px 10px #ddd;
    .synthesize,
    .target {
      .title {
        padding-left: 10px;
        border-left: 4px solid #668bbd;
        font-size: 20px;
        color: #333;
        margin: 10px 0;
        .el-rate {
          margin-left: 30px;
          display: inline-block;
          vertical-align: top;
        }
      }
      .content {
        font-size: 17px;
        line-height: 30px;
        color: #666;
      }
    }
  }
  .portion {
    padding-top: 10px;
    .title {
      padding-bottom: 10px;
    }
    .el-row:last-child {
      border-bottom: 1px solid #ccc;
    }
    .el-row {
      border-left: 1px solid #ccc;
      .el-col {
        border: 1px solid #ccc;
        padding: 10px;
        border-left: none;
        border-bottom: none;
      }
      .el-col:nth-child(odd) {
        background: rgb(243, 243, 243);
      }
    }
  }
  .btnWrap {
    position: absolute;
    height: 240px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    .btn {
      width: 300px;
      height: 50px;
      background: #265ae1;
      text-align: center;
      line-height: 50px;
      color: #fff;
      font-size: 20px;
      border-radius: 10px;
      margin: 0 auto;
      margin-top: 95px;
    }
  }
}
</style>
